import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { theme } from "./core/utils/theme.ts";
import Layout from "./components/Layout.tsx";
import { HomePage } from "./pages/HomePage.tsx";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <HomePage />
      </Layout>
    </ThemeProvider>
  );
};
