"use client";
import { Box } from "@mui/material";
import styles from "./Footer.module.scss";
import {
  EmailRounded,
  LocalPhoneRounded,
  LocationOnRounded,
} from "@mui/icons-material";
import React from "react";

import logo from "../assets/Conmoto_logo.png";
import linkedin from "../assets/linkedin.svg";
import inst from "../assets/inst.svg";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box className={styles.footer} component={"footer"}>
      <div className={styles.footerWrapper}>
        <div className={styles.imgWrapper}>
          <img
            className={styles.logo}
            src={logo}
            alt="Conmoto Logo"
            width={202}
            loading="lazy"
          />
          <a
            className={styles.googleLink}
            href="https://docs.google.com/document/d/1gGO1pFeF-5ZPwpiv4y-s1HDhgOehrTVN/edit?usp=drive_link&ouid=108472662154279096915&rtpof=true&sd=true">
            Terms of service
          </a>
          <a
            className={styles.googleLink}
            href="https://docs.google.com/document/d/1JLz1v1FOgu-UVKVDIdpCu-yce6gW64s4/edit?usp=drive_link&ouid=108472662154279096915&rtpof=true&sd=true">
            Privacy policy
          </a>
        </div>
        <div className={styles.contactus}>
          <p className={styles.title}>Contact Us</p>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <EmailRounded />
              <a
                className={styles.contactLink}
                href="mailto:support@conmoto.io">
                support@conmoto.io
              </a>
            </li>
            <li className={styles.listItem}>
              <LocalPhoneRounded />
              <a className={styles.contactLink} href="tel:+37455942432">
                +374 (55) 94-24-32
              </a>
            </li>
            <li className={styles.listItem}>
              <LocationOnRounded />
              <p>Armenia, Yerevan 0010, str Vazgen Sargsyan 26/1</p>
            </li>
          </ul>
        </div>
        {/* <div className={styles.social}>
          <p className={styles.title}>Follow Us</p>
          <ul className={styles.socialList}>
            <li className={styles.socialListItem}>
              <a
                href="https://www.instagram.com/motagames_studio?igsh=bjdvOXRjYWNjeWV4"
                className={styles.link}>
                <img
                  className={styles.icon}
                  src={inst}
                  alt="Instagram Logo"
                  width={30}
                  height={30}
                />
              </a>
            </li>
            <li className={styles.socialListItem}>
              <a
                href="https://www.linkedin.com/company/mota-games/"
                className={styles.link}>
                <img
                  className={styles.icon}
                  src={linkedin}
                  alt="Linkedin Logo"
                  width={30}
                  height={30}
                />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <div
        className={
          styles.rights
        }>{`© ${currentYear} Mota Games. All rights reserved`}</div>
    </Box>
  );
}
