import { Box } from "@mui/material";
import React, { FC } from "react";
import Vacancies from "../components/Vacancies/Vacancies.tsx";
import ContactUs from "../components/ContactUs/ContactUs.tsx";

export const HomePage: FC = () => {
  return (
    <Box>
      <Vacancies />
      <ContactUs />
    </Box>
  );
};
