"use client";

import { Box } from "@mui/material";
import styles from "./Header.module.scss";
import CircleButton from "./ui/CircleButton/CircleButton.tsx";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { scrollToElement } from "../core/utils/scrollToElement.ts";
import React from "react";
import logo from "../assets/Conmoto_logo.png";

export default function Header() {
  return (
    <Box className={styles.header} component={"header"}>
      <h1 className="visually-hidden">Conmoto</h1>
      <img
        className={styles.logo}
        src={logo}
        alt="Conmoto Logo"
        width={1164}
        height={421}
      />
      <CircleButton event={() => scrollToElement("vacancies")}>
        <ArrowDownwardRoundedIcon
          sx={{
            width: "64px",
            height: "64px",
          }}
        />
      </CircleButton>
    </Box>
  );
}
